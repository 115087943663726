// Load Active Admin's styles into Webpacker,
// see `active_admin.scss` for customization.
//= require activeadmin_addons/all
//= require activeadmin/quill_editor/quill
//= require activeadmin/quill_editor_input

import "../stylesheets/active_admin";

import "@activeadmin/activeadmin";
import "activeadmin_addons" 

const axios = require('axios')
const moment = require('moment');

const initAutoUpdateBuildingSelect = () => {
  const agencySelect = jQuery('#owner_agency_id');
  const buildingSelect = jQuery('#owner_building_ids');
  
  agencySelect.on('change', (event) => {
    if (event.target.value) {
      axios.get(`/admin/buildings?q%5Bagency_id_eq%5D=${event.target.value}&format=json&scope=tous&order=name_asc`).then((response) => {
        buildingSelect.empty();
        response.data.forEach((option) => {
          const newOption = new Option(option.name, option.id);
          buildingSelect.append(newOption);
        });
        buildingSelect.val(null).trigger('change');
        buildingSelect.prop('disabled', false);
      });
    } else {
      buildingSelect.val(null).trigger('change');
      buildingSelect.prop('disabled', true);
    }
  });
};

const initAutoUpdateVpcClosingDate = () => {
  const occuredAtInput = jQuery('#general_assembly_occured_at');
  const vpcClosingDateInput = jQuery('#general_assembly_voting_window_limit');
  occuredAtInput.on('change', (event) => {
    if (event.target.value) {
      const threeDaysBefore = moment(event.target.value).subtract(3, 'days').format('YYYY-MM-DD HH:mm'); 
      vpcClosingDateInput.val(threeDaysBefore);
    }
  })
};

document.addEventListener('DOMContentLoaded', () => {
  initAutoUpdateBuildingSelect();
  initAutoUpdateVpcClosingDate();
})